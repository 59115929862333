.loader {
    width: 210px;
    height: 20px;
    background-color: #e8e8e8;
    border: 1px solid #707070;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.loader-progress-bg {
    width: 95%;
    height: 40%;
    background-color: #b1b1b1;
    border: 1px solid #707070;
    box-sizing: border-box;
}

.loader-progress {
    width: 40%;
    height: 100%;
    border-right: 1px solid #585858;
    /* background-color: #efefef; */
    background: #ffffff;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ffffff 0%, #e5e5e5 97%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #ffffff 0%, #e5e5e5 97%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ffffff 0%, #e5e5e5 97%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e5e5e5', GradientType=0);
    /* IE6-9 */
}